import {Fragment, useEffect, useState} from "react"
import {collection, getDocs, query, where} from "firebase/firestore"
import {db} from "./firebase/Firebase"
import moment from "moment-timezone"

const ShowDriver = ({driver,deviceWidth}) => {
    const [penalties, setPenalties] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let toState = []
            let q = query(collection(db, "penalties"), where("driver", "==", driver))
            let penaltiesSnap = await getDocs(q)
            for (let p of penaltiesSnap.docs) {
                toState.push({id: p.id, ...p.data()})
            }
            toState.sort((a, b) => moment.utc(b.expire).format("x") - moment.utc(a.expire).format("x"))
            setPenalties(toState)
        }
        fetchData()
    }, [driver])

    return <Fragment>
        {deviceWidth>900 && <div className="empty"/>}
        <div className={`${deviceWidth > 900 ? 'gridItem' : 'gridItemWide'} showDriver`}>
            {penalties && penalties.length > 0 &&
                <table className="penaltyTable">
                    <tbody>
                    {penalties.map(p =>
                    <Fragment key={p.id}>
                        <tr
                            style={{color: moment.utc(p.expire).isBefore(moment.utc()) ? 'darkred' : undefined}}>
                            <td>
                                {moment.utc(p.issue).format("D MMM YYYY")}
                            </td>
                            <td>
                                {p.event}
                            </td>
                        </tr>
                        <tr style={{color: moment.utc(p.expire).isBefore(moment.utc()) ? 'darkred' : undefined}}>
                            <td style={{borderBottom: '2px #4714F6 solid'}}>
                                {p.points} point{p.points !== 1 && 's'}
                            </td>
                            <td style={{borderBottom: '2px #4714F6 solid'}}>
                                <b>{p.offence}</b>
                            </td>
                        </tr>
                    </Fragment>
                    )}
                    </tbody>
                </table>
            }

        </div>
    </Fragment>
}

export default ShowDriver
