import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"
import {getAuth, GoogleAuthProvider} from "firebase/auth"

const app = initializeApp(require(`./FirebaseConfig.json`))

const db = getFirestore(app)
const storage = getStorage(app)

const auth = getAuth(app)
const googleProvider = new GoogleAuthProvider()

export {db, storage, auth, googleProvider}
