import {Fragment, useEffect} from "react"
import {getDocs, query, collection, where} from "firebase/firestore"
import {db} from "./firebase/Firebase"
import moment from "moment-timezone"
import ShowDriver from "./ShowDriver"

const {useState} = require("react")

const Overview = ({inactive, toggleActive, deviceWidth}) => {
    const [drivers, setDrivers] = useState([])
    const [penalties, setPenalties] = useState([])
    const [showDriver, setShowDriver] = useState(null)

    useEffect(() => {
        const fetchPenalties = async () => {
            let q = query(collection(db, "penalties"))
            let penaltiesSnap = await getDocs(q)
            let toState = []
            for (let penaltyDoc of penaltiesSnap.docs) {
                let data = penaltyDoc.data()
                if (moment.utc(data.expire).isAfter(moment.utc())) {
                    toState.push({id: penaltyDoc.id, ...data})
                }
            }
            toState.sort((a, b) => a.issue > b.issue ? 1 : -1)
            return setPenalties(toState)
        }
        fetchPenalties()
    }, [])

    useEffect(() => {
        const fetchDrivers = async () => {
            let q = inactive ?
                query(collection(db, "drivers"))
                :
                query(collection(db, "drivers"), where("active", "==", true))

            let driversSnap = await getDocs(q)
            let toState = []
            for (let driverDoc of driversSnap.docs) {
                let points = 0
                let lastExpire = 0
                let nextExpire = 0
                if (penalties.length > 0) {
                    for (let p of penalties) {
                        if (p.driver === driverDoc.id) {
                            points += p.points
                            if (moment.utc(p.expire).isBefore(moment.utc()) && moment.utc(p.expire).isAfter(moment.utc(lastExpire))) lastExpire = p.expire
                            if (moment.utc(p.expire).isAfter(moment.utc()) && (moment.utc(p.expire).isBefore(moment.utc(nextExpire)) || nextExpire === 0)) nextExpire = p.expire
                        }
                    }
                }
                let lastName=driverDoc.data().name.substring(driverDoc.data().name.indexOf(' ') + 1)
                let lastNameParts
                if (lastName.includes('de ')) lastNameParts=lastName.split('de ')
                if (lastName.includes('di ')) lastNameParts=lastName.split('di ')
                let sortName=lastNameParts ? lastNameParts[lastNameParts.length-1] : lastName
                toState.push({
                    id: driverDoc.id,
                    points: points,
                    lastExpire: lastExpire !== 0 ? lastExpire : 0,
                    nextExpire: nextExpire !== 0 ? nextExpire : 0,
                    lastName: lastName,
                    sortName: sortName,
                    ...driverDoc.data()
                })
            }
            // toState.sort((a, b) => b.points - a.points)
            toState.sort((a, b) => b.points - a.points || a.sortName.localeCompare(b.sortName))
            return setDrivers(toState)
        }

        if (penalties && penalties.length > 0) fetchDrivers()
    }, [inactive, penalties])


    return <div className="Overview">
        <p className="title">F1 PENALTY POINTS</p>
        <p className="toggleInactive" onClick={() => toggleActive()}>(showing {inactive ? 'all' : 'current'} drivers,
            click here to toggle)</p>
        {drivers && drivers.length > 0 &&
            <div className="gridContainer">
                {drivers.map((d) =>
                    <Fragment key={d.id}>
                        <div className="gridItem driver"
                             onClick={() => showDriver === d.id ? setShowDriver(null) : setShowDriver(d.id)}>
                            <span className="driverName">{d.name.substring(0, d.name.indexOf(' '))}</span><br/>
                            <span className="driverName">{d.name.substring(d.name.indexOf(' ') + 1)}</span>
                        </div>
                        <div className="gridItem points" style={{
                            background: `linear-gradient(90deg, #4714F6 0%,#4714F6 ${Math.floor((d.points / 12) * 100) - 2}%, royalblue ${Math.floor((d.points / 12) * 100) + 2}%, royalblue 100%)`
                        }} onClick={() => showDriver === d.id ? setShowDriver(null) : setShowDriver(d.id)}>
                            <span className="pointsPoints">{d.points} point{d.points !== 1 && 's'}</span><br/>
                            <span className="pointsText">
                                    {d.points === 0 ?
                                        d.lastExpire !== 0 && 'since ' + moment.utc(d.lastExpire).format('D MMM YYYY') :
                                        d.nextExpire !== 0 && 'next drop ' + moment.utc(d.nextExpire).format('D MMM YYYY')
                                    }
                                </span>
                        </div>
                        {showDriver === d.id &&
                            <ShowDriver driver={d.id} deviceWidth={deviceWidth}/>
                        }
                    </Fragment>
                )}
            </div>
        }
    </div>
}

export default Overview
