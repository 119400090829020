import {auth, db, googleProvider} from "./firebase/Firebase"

import {signInWithPopup,onAuthStateChanged} from "firebase/auth"
import {collection, getDocs, query, addDoc} from "firebase/firestore"

const {useState, useEffect} = require("react")

const Admin = () => {
    const [uid, setUid] = useState(null)
    const [drivers, setDrivers] = useState([])

    useEffect(() => {
        const fetchDrivers = async () => {
            let q = query(collection(db, "drivers"))
            let driversSnap = await getDocs(q)
            let toState = []
            for (let driverDoc of driversSnap.docs) {
                toState.push({id: driverDoc.id, ...driverDoc.data()})
            }
            toState.sort((a, b) => a.name.localeCompare(b.name))
            return setDrivers(toState)
        }
        fetchDrivers()
    }, [])

    const saveData = async (e) => {
        e.preventDefault()
        let data = e.target
        let newData = {
            driver: data.driver.value,
            event: data.event.value,
            expire: data.expire.value,
            issue: data.issue.value,
            offence: data.offence.value,
            points: parseInt(data.points.value, 10)
        }
        return await addDoc(collection(db, "penalties"), newData)
            .then(()=>
            document.getElementById("formulier").reset())
    }

    const signInWithGoogle = () => {
        signInWithPopup(auth, googleProvider).then((result) => {
            if (result.user.email === "ziggyziggyziggy@gmail.com") {
                setUid(result.user.uid)
            } else {
                setUid(null)
            }
        })
    }

    // onAuthStateChanged(auth,(user)=> {
    //     if (user.email === "ziggyziggyziggy@gmail.com") {
    //         setUid(user.uid)
    //     } else {
    //         setUid(null)
    //     }
    // })
    //
    //
    return <div className="admin">
        {!uid && <div onClick={() => signInWithGoogle()} title="Log in">log in</div>}
        {uid === "D6Mo6l1uX5eEuPzyceLZ34K3rxC3" &&
            <div>
                <h2>Add penalty</h2>
                <form onSubmit={(e) => saveData(e)} id="formulier">
                    <table>
                        <tbody>
                        <tr>
                            <td>name</td>
                            <td><select name="driver">
                                {drivers.map(d =>
                                    <option key={d.id} value={d.id}>{d.name}</option>
                                )
                                }
                            </select></td>
                        </tr>
                        <tr>
                            <td>event</td>
                            <td><input type="text" name="event"/></td>
                        </tr>
                        <tr>
                            <td>offence</td>
                            <td><input type="text" name="offence"/></td>
                        </tr>
                        <tr>
                            <td>points</td>
                            <td><input type="text" name="points"/></td>
                        </tr>
                        <tr>
                            <td>issued</td>
                            <td><input type="text" name="issue"/>&nbsp;yyyy-mm-dd</td>
                        </tr>
                        <tr>
                            <td>expires</td>
                            <td><input type="text" name="expire"/>&nbsp;yyyy-mm-dd</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td><input type="submit"/>&nbsp;<input type="reset"/></td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        }
    </div>
}

export default Admin