import './App.css';
import Overview from "./Overview"
import {useEffect, useState} from "react"

import {Switch, Route, BrowserRouter} from "react-router-dom";

import "normalize.css";
import Admin from "./Admin"

function App() {
    const [inactive, setInactive] = useState(false)
    const [deviceWidth, setDeviceWidth] = useState(null);

    useEffect(() => {
        setDeviceWidth(
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        );
    }, [])

    useEffect(() => {
        window.addEventListener("resize", () =>
            setDeviceWidth(
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
            )
        );
        return window.removeEventListener("resize", null);
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path={'/admin'}>
                        <Admin/>
                    </Route>
                    <Route>
                        <Overview inactive={inactive} toggleActive={() => setInactive(!inactive)}
                                  deviceWidth={deviceWidth}/>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
